/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import 'primeicons/primeicons.css';

@import '@ionic/angular/css/core.css'; // /* Core CSS required for Ionic components to work properly */

@import '@ionic/angular/css/normalize.css'; // /* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

@import '@ionic/angular/css/padding.css'; // /* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import '@angular/cdk/overlay-prebuilt.css';


//https://coolors.co/ffdc5e-527cbe-f27068-1ccad8-4bbd97-f8f7ff

:root {
    --primary-color: #F27068;
    --secondary-color: #000050;
    --third-color: #fff8f0;
    --info-color: #E15A97;
    --warm-color: #f11018;
    --info-color: #3f84e5;
    --primary-color-text:$appyellow
    --primary-color: #E15A97;

    --p-tabs-tabpanel-background: transparent !important;
  }

:root {
  --ion-background-color: linear-gradient(to bottom, #fcd7d5, #c9f7fa);
  --ion-tab-bar-background: white;
  --ion-toolbar-background: white;
  --ion-item-background: transparent;
  --ion-color-primary: #f27068;
  --ion-color-secondary: #006600;
  --ion-color-secondary-rgb: 0, 102, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #005a00;
  --ion-color-secondary-tint: #1a751a;
}

ion-modal {
  // Change globally style for modals since when you open by controller there is no reference to the modal
  --background: transparent;
  --backdrop-opacity: 0.3;
}

ion-modal::part(content) {
  backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.24);
}

@media screen and (max-width: 991px) {
  .layout-sidebar {
    background: white !important;
  }
}

.clickable {
  cursor: pointer;
}

// Style for the card.

.p-card {
  background-color: white !important;
  border-radius: 50px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  border: 2px solid var(--primary-color, #2326d3) !important;
  transition: all 0.9s ease;
}

.p-card::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 50px !important;
  background-color: var(--primary-color, #2326d3) !important;
  z-index: -1 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
}

/* Add this to control content overflow while keeping the ::before visible */
.p-card-body {
  position: relative;
  overflow: hidden;
  border-radius: 48px;
}

.p-card:hover {
  // Bug: not sure why front cant have translations becouse the after elements put in front
  box-shadow: 1px 8px 16px rgba(0, 0, 0, 0.2);
}

.p-card:hover::before {
  box-shadow: 1px 8px 16px rgba(0, 0, 0, 0.3);
  transform: translateY(7px) scale(0.99);
  opacity: 0.9;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  // background-image: url('/assets/background/anni.webp');
  background-image: url('/assets/background/sun-tornado2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: float 40s ease-in-out infinite;
}
