@import 'node_modules/ngx-easy-table/style.scss';

@import url('https://fonts.googleapis.com/css2?family=Palanquin:wght@300&display=swap');

* {
  font-family: 'Palanquin', sans-serif;
}

@import 'assets/layout/styles/layout/layout.scss';
// @import 'node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
@import './shepherd.scss';

.pointer {
  cursor: pointer;
}

.clickeable {
  cursor: pointer;
  color: rgb(64, 64, 218);
}

.align-end {
  text-align: end;
}

blockquote {
  margin: 0;
  background: #eee;
  padding-top: 5px;
  padding-left: 25px;
  border-radius: 5px;
}

// General styles
.center {
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #2d4e7e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f27068;
  border-radius: 10px;
}

.blur {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.747);
  border: 1px solid rgba(255, 255, 255, 0.014);
  padding: 5px;
}

.promo-button {
  display: inline-block;
  padding: 14px 32px;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(135deg, #ff6b6b 0%, #ff3b3b 100%);
  border: none;
  border-radius: 50px;
  box-shadow: 0 6px 12px rgba(255, 107, 107, 0.3), 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  letter-spacing: 0.5px;
}

.promo-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  transition: all 0.6s;
  z-index: -1;
}

.promo-button:hover {
  background: linear-gradient(135deg, #ff8787 0%, #ff5252 100%);
  box-shadow: 0 10px 20px rgba(255, 107, 107, 0.4), 0 3px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px) scale(1.02);
  letter-spacing: 0.8px;
}

.promo-button:hover:before {
  left: 100%;
}

.promo-button:active {
  background: linear-gradient(135deg, #fa5252 0%, #e03131 100%);
  box-shadow: 0 3px 6px rgba(255, 107, 107, 0.2);
  transform: translateY(0) scale(0.98);
  transition: all 0.1s;
}

@keyframes pulse {
  0% {
    box-shadow: 0 6px 12px rgba(255, 107, 107, 0.3);
  }
  50% {
    box-shadow: 0 6px 18px rgba(255, 107, 107, 0.6);
  }
  100% {
    box-shadow: 0 6px 12px rgba(255, 107, 107, 0.3);
  }
}

.promo-button {
  animation: pulse 2s infinite;
}

.promo-button:hover {
  animation: none;
}
