$primary: #db7134;
$secundary: #257f52;
$other: #3602b8;

$appsalmon: #F27068;
$appyellow: #FFDC5E;
$appblue:#527CBE;
$applightblue: #1CCAD8;
$appgrenn: #4BBD97;
$appwhite:#08080a;

//https://coolors.co/ffdc5e-527cbe-f27068-1ccad8-4bbd97-f8f7ff

:root {
    --primary-color: #F27068;
    --secondary-color: #000050;
    --third-color: #fff8f0;
    --info-color: #E15A97;
    --warm-color: #f11018;
    --info-color: #3f84e5;
    --primary-color-text:$appyellow
    --primary-color: #E15A97;
  }