.card {
    border-radius: 8px;
    background: var(--surface-card);
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;

    .card-header {
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .card-subtitle {
        color: var(--text-color-secondary);
        font-size: 0.857rem;
        font-weight: 500;
        margin: -1rem 0 1rem 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 70px;
    }
}

.ng-hidden {
    display: none !important;
}
