* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $scale;
}

body {
  font-weight: 400;
  padding: 0;
  margin: 0;
  min-height: 100%;
  color: var(--text-color);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.layout-container {
  // background: var(--body-bg);

  // TODOL elegir algunos colores finales
  background-image: linear-gradient(40deg, rgba(255, 165, 120, 0.5), rgba(242, 31, 31, 0) 70.71%),
    linear-gradient(220deg, rgba(255, 255, 255, 0.7), rgba(0, 255, 0, 0) 100%), linear-gradient(284deg, rgba(125, 184, 243, 0.5), rgba(0, 0, 255, 0) 60%);

  min-height: 100vh;

  &:before {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    // TODO esta propiedad hace que no pueda picar en inputs, en mi versión actual, pero la nueva si funciona
    // content: "";
    z-index: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: var(--body-image);
    background-repeat: no-repeat;
  }

  .layout-content-wrapper {
    position: relative;
    z-index: 1;
    overflow-x: hidden;
  }
}
