/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


@import "@ionic/angular/css/core.css"; // /* Core CSS required for Ionic components to work properly */

@import "@ionic/angular/css/normalize.css"; // /* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

@import "@ionic/angular/css/padding.css"; // /* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import '@angular/cdk/overlay-prebuilt.css';


:root {
  --ion-background-color: linear-gradient(to bottom, #fcd7d5, #c9f7fa);
  --ion-tab-bar-background	: white;
  --ion-toolbar-background	: white;
  --ion-item-background	: transparent;
  --ion-color-primary: #F27068;
  --ion-color-secondary: #006600;
  --ion-color-secondary-rgb: 0, 102, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #005a00;
  --ion-color-secondary-tint: #1a751a;
}
/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import '@ionic/angular/css/palettes/dark.system.css';
// @import "@ionic/angular/css/palettes/dark.always.css";

ion-modal {
    // Change globally style for modals since when you open by controller there is no reference to the modal
    --background: transparent;
    --backdrop-opacity	: .3;
  }
  
  
ion-modal::part(content) {
    backdrop-filter: blur(3px);
    background: rgba(255, 255, 255, 0.24);
  }
  
  

@media screen and (max-width: 991px) {
   .layout-sidebar {
      background: white !important;
  }
}