@import "node_modules/ngx-easy-table/style.scss";

// @import "node_modules/swiper/swiper-bundle.css";




@import url('https://fonts.googleapis.com/css2?family=Palanquin:wght@300&display=swap');


* {
  font-family: 'Palanquin', sans-serif;
  }



@import "assets/layout/styles/layout/layout.scss";
@import 'node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';

.pointer{
  cursor: pointer;
}


.clickeable {
  cursor: pointer;
  color: rgb(64, 64, 218);
}


.align-end {
  text-align: end;
}


blockquote {
  margin: 0;
  background: #eee;
  padding-top: 5px;
  padding-left: 25px ;
  border-radius: 5px;
}

// General styles
.center{
  text-align: center;
}


/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #2d4e7e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F27068;
  border-radius: 10px;
}

.blur{
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.747);
  border: 1px solid rgba(255, 255, 255, 0.014);
  padding: 5px;
}



.promo-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: #ff6b6b;
  border: none;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.promo-button:hover {
  background-color: #ff8787;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  animation: none;
}

.promo-button:active {
  background-color: #fa5252;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
}